import { Component } from "react";
import experience from "./molecules/experience.json";
import education from "./molecules/education.json";
import ExperienceEntry from "./molecules/ExperienceEntry";
import EducationEntry from "./molecules/EducationEntry";

import icon_figma from "../tool_figma.svg"
import icon_after_effects from "../tool_after_effects.svg"
import icon_illustrator from "../tool_illustrator.svg"
import icon_webstorm from "../tool_webstorm.svg"
import icon_protopie from "../tool_protopie.svg"
import icon_pycharm from "../tool_pycharm.svg"

import icon_react from "../tool_react.svg"
import icon_tailwind from "../tool_tailwind.svg"
import icon_python from "../tool_python.svg"
import icon_sass from "../tool_sass.svg"
import icon_typescript from "../tool_typescript.svg"

class Resume extends Component {
    constructor(props) {
        super(props);
        this.tools = [
            { "name": "Figma", "icon": icon_figma },
            { "name": "Adobe After Effects", "icon": icon_after_effects },
            { "name": "Adobe Illustrator", "icon": icon_illustrator },
            { "name": "ProtoPie", "icon": icon_protopie },
            { "name": "JetBrains Webstorm", "icon": icon_webstorm },
            { "name": "JetBrains PyCharm", "icon": icon_pycharm },
        ]
        this.langs = [
            { "name": "Python", "icon": icon_python },
            { "name": "React", "icon": icon_react },
            { "name": "TypeScript", "icon": icon_typescript },
            { "name": "Sass/SCSS", "icon": icon_sass },
            { "name": "TailwindCSS", "icon": icon_tailwind },
        ]
        this.skills = ["UX Design", "Information Architecture", "User Task Mapping", "Product Design",
            "Rapid Prototyping", "Usability Testing", "Design Systems"]
    }
    render () {
        return (
            <section className="intro col-sm-8 col-sm-push-2">
                <header className="col-sm-12">
                    <h2 className="heading-section">Résumé</h2>
                </header>
                <div className="col-sm-8">
                    <h3 className="heading-1">Recent Experience</h3>
                    <ul className="experienceList">
                        {experience.map(( job ) => (
                            <ExperienceEntry key={job.key} job={job} />
                        ))}
                    </ul>
                    <h3 className="heading-1">Education</h3>
                    <ul className="educationList">
                        {education.map(( learned ) => (
                            <EducationEntry key={learned.key} cert={learned} />
                        ))}
                    </ul>
                </div>
                <div className="col-sm-4">
                    <h3 className="heading-1">Skills</h3>
                    <ul className="skillsList">
                        {this.skills.map( ( skill ) => (
                                <li key={skill} className="skillsList-item">{skill}</li>
                        ))}
                    </ul>
                    <h3 className="heading-1">Software</h3>
                    <ul className="toolsList">
                        {this.tools.map( ( tool ) => (
                            <li key={tool.name} className="toolsList-item"><img src={tool.icon} alt={tool.name}/></li>
                        ))}
                    </ul>
                    <h3 className="heading-1">Tools</h3>
                    <ul className="toolsList">
                        {this.langs.map( ( lang ) => (
                            <li key={lang.name} className="toolsList-item"><img src={lang.icon} alt={lang.name}/></li>
                        ))}
                    </ul>
                </div>
            </section>
        )
    }
}

export default Resume;