import { Component } from "react";

class ExperienceEntry extends Component {
    constructor(props) {
        super(props);
        this.job = props.job
    }
    render() {
        return (
            <li className="experienceList-item">
                <div className="dates">
                    <span className="dates-end">{this.job.dates.end}</span>
                    <span className="dates-start">{this.job.dates.start}</span>
                </div>
                <div className="experienceContent">
                    <h4 className="listItemHead">{this.job.title}</h4>
                    <h5 className="listItemSubhead">{this.job.company}</h5>
                    <ul>
                        {this.props.job.content.map( ( row ) => (
                            <li key={row}>{row}</li>
                        ))}
                    </ul>
                </div>
            </li>
        )
    }
}

export default ExperienceEntry;