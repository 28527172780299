const Modal = ({ handleClose, show, entry }) => {
    const showHideClassNameModal = show ? "case modal display-block" : "case modal display-none";
    const showHideClassNameShade = show ? "shade display-block" : "shade display-none";
    return (
        <>
            <div className={showHideClassNameModal}>
                <button className="caseCard-close" onClick={handleClose} title="Close modal"><svg viewBox="0 0 20 19.84" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M10.17,10l3.89-3.89a.37.37,0,1,0-.53-.53L9.64,9.43,5.75,5.54a.37.37,0,1,0-.53.53L9.11,10,5.22,13.85a.37.37,0,0,0,0,.53.34.34,0,0,0,.26.11.36.36,0,0,0,.27-.11l3.89-3.89,3.89,3.89a.34.34,0,0,0,.26.11.35.35,0,0,0,.27-.11.37.37,0,0,0,0-.53Z"/></svg></button>
                <header className="caseCard">
                    <div className="caseCard-rotated">
                        <div className="caseCard-meta col-xs-4">
                            <div className="meta-title">Client</div>
                            <div className="meta-value">{entry.client}</div>
                        </div>
                        <div className="caseCard-meta col-xs-4">
                            <div className="meta-title">Date</div>
                            <div className="meta-value">{entry.date}</div>
                        </div>
                    </div>
                    <div className="caseCard-rotated mod-title">
                        <div className="caseCard-title">
                            <h3 className="heading">{entry.title}</h3>
                            <p className="summary">{entry.tagline}</p>
                        </div>
                    </div>
                </header>
                <div className="caseContent">

                    <div className="content col-sm-12">
                        <div className="col-sm-8">
                            <h3 className="heading-1">Overview</h3>
                            {entry.detail.overview.map(( p, idx ) => (
                                <p key={'descriptionParagraph-' + idx}>{p}</p>
                            ))}
                        </div>
                        <div className="col-sm-4">
                            <h3 className="heading-1">Skills</h3>
                            <ul className="skillsList">
                                {entry.detail.skills.map( ( skill ) => (
                                    <li key={skill} className="skillsList-item">{skill}</li>
                                ))}
                            </ul>
                            <h3 className="heading-1">Tools</h3>
                            <ul className="toolsList">
                                {entry.detail.tools.map( ( tool ) => (
                                    <li key={tool.name} className="toolsList-item"><img src={tool.icon} alt={tool.name}/></li>
                                ))}
                            </ul>
                        </div>

                    </div>

                    <div className="caseImage col-sm-12">
                        {
                            entry.detail.screenshots.map( ( shot ) => (
                                <figure key={shot.file}>
                                    <a href={'/img/' + shot.file + '@1920w.png'} target="_blank" rel="noreferrer">
                                        <picture>
                                            <source media="(min-width:1920px)" srcSet={'/img/' + shot.file + '@1920w.png'} />
                                            <source media="(max-width:1200px)" srcSet={'/img/' + shot.file + '@1200w.png'} />
                                            <source media="(max-width:1024px)" srcSet={'/img/' + shot.file + '@1024w.png'} />
                                            <source media="(max-width:465px)" srcSet={'/img/' + shot.file + '@768w.png'} />
                                            <img src={'/img/' + shot.file + '@1920w.png'} alt="screenshot" />
                                        </picture>
                                        <figcaption>{shot.caption}</figcaption>
                                    </a>
                                </figure>
                            ))
                        }

                    </div>
                </div>
            </div>
            <div className={showHideClassNameShade} onClick={handleClose}></div>
        </>
    )
}

export default Modal;