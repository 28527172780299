import { Component } from "react";
import Modal from "./Modal";

class PortfolioEntry extends Component {
    constructor(props) {
        super(props);
        this.entry = props.entry
        this.showModal = props.showModal
        this.hideModal = props.hideModal
    }
    render() {
        return (
            <>
                <Modal show={this.props.show} handleClose={this.hideModal} entry={this.props.selectedEntry} />
                <button className="case mod-button" onClick={() => { this.showModal(this.entry) }}>

                    <header className="caseCard">
                        <div className="caseCard-rotated">
                            <div className="caseCard-meta col-xs-4">
                                <div className="meta-title">Client</div>
                                <div className="meta-value">{this.entry.client}</div>
                            </div>
                            <div className="caseCard-meta col-xs-4">
                                <div className="meta-title">Date</div>
                                <div className="meta-value">{this.entry.date}</div>
                            </div>
                        </div>
                        <div className="caseCard-rotated mod-title">
                            <div className="caseCard-title">
                                <h3 className="heading">{this.entry.title}</h3>
                                <p className="summary">{this.entry.tagline}</p>
                            </div>
                        </div>
                    </header>
                    <div className="caseImage">
                        <picture>
                            <source media="(min-width:1920px)" srcSet={'/img/' + this.entry.keyImage + '@1920w.png'} />
                            <source media="(max-width:1200px)" srcSet={'/img/' + this.entry.keyImage + '@1200w.png'} />
                            <source media="(max-width:1024px)" srcSet={'/img/' + this.entry.keyImage + '@1024w.png'} />
                            <source media="(max-width:465px)" srcSet={'/img/' + this.entry.keyImage + '@768w.png'} />
                            <img src={'/img/' + this.entry.keyImage + '@1920w.png'} alt="screenshot" />
                        </picture>
                        {/*<img src={'/img/' + this.entry.keyImage + '@1920w.png'} alt="screenshot" />*/}
                    </div>
                </button>
            </>
        )
    }
}

export default PortfolioEntry;