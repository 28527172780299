import { Component } from "react";

class EducationEntry extends Component {
    constructor(props) {
        super(props);
        this.cert = props.cert
    }
    render() {
        return (
            <li className="educationList-item">
                <div className="dates">
                    <span className="dates-end">{this.cert.dates}</span>
                </div>
                <div className="experienceContent">
                    <h4 className="listItemHead">{this.cert.certification}</h4>
                    <h5 className="listItemSubhead">{this.cert.institution}</h5>
                </div>
            </li>
        )
    }
}

export default EducationEntry;