import './scss/style.css';
import Header from "./components/Header";
import Introduction from "./components/Introduction";
import Portfolio from "./components/Portfolio";
import Resume from "./components/Resume";

import {ReactComponent as IconDribbble} from "./social_dribbble.svg"
// import {ReactComponent as IconBehance} from "./social_behance.svg"
import {ReactComponent as IconGithub} from "./social_github.svg"
import {ReactComponent as IconLinkedin} from "./social_linkedin.svg"

function App() {

    const social = [
      { name: "LinkedIn", icon: <IconLinkedin />, url: "https://www.linkedin.com/in/ux-unicorn" },
      { name: "Dribbble", icon: <IconDribbble />, url: "https://www.dribbble.com/ux-unicorn" },
      // { name: "Behance", icon: <IconBehance />, url: "https://www.behance.net/jasonfukura" },
      { name: "GitHub", icon: <IconGithub />, url: "https://www.github.com/jfukura" },
    ]

    return (
        <main className="page container-fluid">
        <Header />
        <Introduction />
        <Portfolio/>
        <Resume />
        <footer className="col-sm-8 col-sm-push-2 footer">
            <div className="col-sm-8 footer-group">
                <header className="">
                    <h2 className="heading-section" style={{marginTop:0}}>Connect with me!</h2>
                </header>
                <ul className="socialList">
                    {social.map(( row ) => (
                        <li key={row.url} className="socialList-item"><a href={row.url}
                                                           className="target"
                                                           target="_blank"
                                                           rel="noreferrer">{row.icon}</a></li>
                    ))
                    }
                </ul>
                <p className="footer-tagline">&copy;2023 Made with ☕ in Kitsap County</p>
            </div>
            {/*<div className="col-sm-4 col-sm-pull-8">*/}
            {/*    <h2 className="footer-title">Thanks!</h2>*/}
            {/*</div>*/}
        </footer>
    </main>
    );
}

export default App;
