import { Component } from "react";
import LogoSm from "../img/UXUnicorn_Transparent@400w.png";
import Logo from "../img/UXUnicorn_Transparent@768w.png";

class Header extends Component {
    render () {
        return (
            <header className="header col-sm-12">
                <aside className="col-sm-5 col-sm-push-7">
                    {/* I think this is right? */}
                    <img src={LogoSm}
                         srcSet={`${LogoSm} 300w, ${Logo} 1200w`}
                         sizes="(max-width: 600px) 480px, 1200px"
                         alt="UX Unicorn Logo"
                         className="header-logo"
                    />
                </aside>
                <div className="col-sm-7 col-sm-pull-5">
                    <h1 className="title">User-Centered Designer</h1>
                    <h2 className="subtitle">
                        <ul className="subtitle-list">
                            <li className="subtitle-list-item">UX</li>
                            <li className="subtitle-list-item">Design Systems</li>
                            <li className="subtitle-list-item">Prototyping</li>
                        </ul>
                    </h2>
                </div>
            </header>
        )
    }
}

export default Header;