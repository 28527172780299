import { Component } from "react";
import PortfolioEntry from "./molecules/PortfolioEntry";

// import icon_figma from "../tool_figma.svg"
// import icon_after_effects from "../tool_after_effects.svg"
// import icon_illustrator from "../tool_illustrator.svg"
import icon_webstorm from "../tool_webstorm.svg"
// import icon_protopie from "../tool_protopie.svg"
// import icon_pycharm from "../tool_pycharm.svg"

import icon_sublime from "../tool_sublime.svg"
import icon_fireworks from "../tool_fireworks.svg"
import icon_xd from "../tool_xd.svg"
import icon_elixir from "../tool_elixir.svg"
import icon_sass from "../tool_sass.svg"
import icon_javascript from "../tool_javascript.svg"

class Portfolio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            entry: {detail:{overview:[],skills:[],tools:[],screenshots:[]}}
        }
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        window.addEventListener('scroll', () => {
            document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
        });
    }

    showModal = (entry) => {
        this.setState({ show: true });
        this.setState({ entry: entry });
        const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
        const body = document.body;
        body.style.position = 'fixed';
        body.style.top = `-${scrollY}`;
    };

    hideModal = () => {
        this.setState({ show: false });
        const body = document.body;
        const scrollY = body.style.top;
        body.style.position = '';
        body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
        setTimeout(() => {
            this.setState({ entry: {detail:{overview:[],skills:[],tools:[],screenshots:[]}} })
        }, 500)
    };

    render () {
        const portfolio = [
            {
                "key": 1,
                "title": "ottoBox",
                "client": "otto",
                "date": "2021",
                "tagline": "Making run-time security a little more user friendly.",
                "keyImage": "case_ottobox",
                "detail": {
                    "overview": ["As the Director of UX at otto, I led the design of the user experience for ottoBox—a B2B SaaS application aimed at managing runtime vulnerabilities in web applications and websites. The primary goal was to develop a user-friendly interface that conveyed the message that ottoBox was an \"inbox\" where users could check, review, and clear tasks on a daily basis, but without the need for constant monitoring of a platform.",
                        "The design process involved several rounds of internal review and customer feedback, ensuring that the team was heading in the right direction. This iterative approach allowed the team to fine-tune the user interface to align with user expectations and preferences, ultimately leading to an enhanced user experience.",
                        "To tackle the complexities of the project, I contributed in building the front-end of the application using Javascript and connecting it with the Elixir back-end.",
                        "Looking ahead, the team plans to implement AI-based features in the future to further declutter the inbox. This involves incorporating advanced algorithms capable of differentiating noise from actual potential threats, streamlining the issue management process and further enhancing the user experience.",
                        "Through a combination of meticulous design iterations, user feedback, and technical expertise, the project successfully resulted in ottoBox, a user-friendly and efficient runtime security management application."],
                    "skills": ["UX Design", "User Research", "Prototyping", "JavaScript", "Elixir"],
                    "tools": [
                        { "name": "JetBrains Webstorm", "icon": icon_webstorm },
                        { "name": "Adobe XD", "icon": icon_xd },
                        { "name": "JavaScript", "icon": icon_javascript },
                        { "name": "Sass/SCSS", "icon": icon_sass },
                        { "name": "Elixir", "icon": icon_elixir },
                    ],
                    "screenshots": [{"file": "case_ottobox", "caption": "Screenshot of the product built with JavaScript and Elixir."}]
                }
            },
            {
                "key": 2,
                "title": "MDR Dashboard",
                "client": "CI Security",
                "date": "2018",
                "tagline": "SOC-as-a-service, visualized.",
                "keyImage": "case_mdr_dashboard",
                "detail": {
                    "overview": [
                        "A delicate equilibrium must be struck when operating as an MDR (Managed Detection and Response) company. On the one hand, there's a need to demonstrate proactive engagement to clients; on the other, it's essential to communicate that there's no expectation for them to undertake analytical roles since that's precisely why our expertise exists.",
                        "To utilize CI Security's SOC-as-a-service systems, clients must deploy an appliance at the edge of their network. This deployment facilitates CI Security in tracking both north-south and east-west data flows within the network. However, this generates a substantial amount of data, posing a challenge in rendering it comprehensible.",
                        "Drawing insights from our team of cybersecurity experts who've interacted extensively with clients and potential leads, the process commenced with an in-person whiteboarding session. The objective was to determine the most pertinent data for clients and establish fundamental requirements.",
                        "Progressing from wireframes to subsequent discussions, a more vibrant iteration emerged. This version encapsulated refined concepts for the primary charts. Interestingly, this evolution raised a pertinent question: Are we inadvertently encouraging users to take on an analytical role?",
                        "To address this concern, I developed a prototype using D3.js. This prototype, informed by the refinements, aimed to assess the user experience. To deter any notion of active monitoring by the user, UI fidelity was intentionally lowered. Constructed with HTML, JS, and D3, the prototype integrated sanitized data from an authentic data collector.",
                        "Overall, this process encapsulates our journey from initial ideation to tangible testing, emphasizing user-centric design and user experience."
                    ],
                    "skills": ["UX Design", "User Research", "Prototyping", "JavaScript", "D3.js"],
                    "tools": [
                        { "name": "JetBrains Webstorm", "icon": icon_webstorm },
                        { "name": "Adobe XD", "icon": icon_xd },
                        { "name": "JavaScript", "icon": icon_javascript },
                        { "name": "Sass/SCSS", "icon": icon_sass },
                    ],
                    "screenshots": [
                        {"file": "case_mdr_dashboard_wires", "caption": "Very early wireframes for the UI."},
                        {"file": "case_mdr_dashboard", "caption": "An early mockup of the path that we chose for the UI. From this point we ended up refining and removing data points to make the interface simpler."},
                        {"file": "case_mdr_dashboard_prototype", "caption": "Prototype of the design built with D3.js. Note in this variant we moved to a \"heatmap\" visual for data flow"}
                    ]
                }
            },
            {
                "key": 3,
                "title": "Retire MyWay",
                "client": "Capital One",
                "date": "2020",
                "tagline": "Retirement planning without the existential crisis.",
                "keyImage": "case_retiremyway",
                "detail": {
                    "overview": [
                        "In retirement planning, striking a balance between simplicity and accuracy is crucial. While it's unrealistic to expect comprehensive retirement advice from an unlinked site, it's still essential to provide users with a high-level view of their retirement journey without overwhelming them. The challenge lies in gathering sufficient information to generate reasonably accurate results while keeping the user experience engaging. Considering the marketing nature of such tools, brevity is critical to prevent user drop-offs due to lengthy processes.",
                        "A significant challenge in retirement planning is predicting the timeframe for which users will rely on their retirement funds. The conventional approach of requesting an expected age of death proved discouraging for users. Hence, an innovative solution was devised. Following a comprehensive analysis of competitor offerings, a fresh perspective emerged. A natural language form that mimics the format of Madlibs was adopted, departing from the conventional form-based approach. This inventive design aimed to simplify the interaction and foster user engagement, as fewer questions often lead to higher response rates in such scenarios.",
                        "The issue of asking users about their projected \"age of death\" was circumvented by utilizing data from the Social Security Administration's Actuary Table. By predicting the user's approximate lifespan based on gender and age, the tool eliminated a potentially uncomfortable question and offered a more user-friendly experience. Moreover, to ensure versatility and accessibility, the resulting application was transformed into a jQuery plugin that could be seamlessly integrated into various web pages.",
                        "The results of this innovative approach were promising. User feedback highlighted the favorable reception of the navigational elements and the conversational design, which simplified understanding. Segmented layouts minimized distractions, contributing to a smoother user experience. The practice of prefilling retirement ages at standard points was endorsed. Estimating the age of death emerged as a significant breakthrough for simplicity and engagement. However, improvements were identified, including enhancing step navigation, distinguishing between different links, and implementing thorough validation processes.",
                        "In conclusion, transforming retirement planning from a technical form to a conversational Madlibs-style interaction marks a notable user engagement and simplicity achievement. This approach, supported by data-driven predictions and expert design choices, has the potential to revolutionize how users approach retirement planning tools. Despite the need for some fine-tuning, the positive reception and promising results indicate that this approach may pave the way for more intuitive and user-friendly retirement planning experiences in the future."
                    ],
                    "skills": ["UX Design", "User Research", "Prototyping", "JavaScript"],
                    "tools": [
                        { "name": "Adobe XD", "icon": icon_xd },
                        { "name": "Sublime Text", "icon": icon_sublime },
                        { "name": "JavaScript", "icon": icon_javascript },
                        { "name": "Sass/SCSS", "icon": icon_sass },
                    ],
                    "screenshots": [
                        {"file": "case_retiremyway", "caption": "Screenshot of the first step, as built for user testing. We used UserTesting.com to validate our concept quickly."},
                        {"file": "case_retiremyway_step3", "caption": "The user in this step may select their investing style to see a breakdown of stocks and equity, as well as some basic bullet points for those types."},
                        {"file": "case_retiremyway_end", "caption": "The final screen shows where the user is as far as saving goals go. The user may move the slider to see what changing their savings plan could do for their overall retirement."},
                    ]
                }
            },
            {
                "key": 4,
                "title": "Kelly Hoppen by apaiser",
                "client": "apaiser",
                "date": "2015",
                "tagline": "Landing page for an iconic collaboration.",
                "keyImage": "case_hoppen_key",
                "detail": {
                    "overview": [
                        "In the bustling digital design and collaboration landscape, 2015 marked a pivotal moment in my career journey when I received a prestigious invitation from apaiser. Tasked with spearheading the design and development of a captivating landing page, I embarked on a thrilling adventure to showcase their groundbreaking partnership with the illustrious British interior designer Kelly Hoppen CBE.",
                        "Fuelled by passion and creativity, I joined forces with the dynamic Australian-based team, igniting a synergy to set the stage for an unforgettable digital experience. Our mission? To craft a virtual masterpiece that would capture the essence of this high-profile collaboration and leave a lasting imprint on the minds of visitors.",
                        "From the project's very inception, we delved deep into the realm of imagination, sketching out our vision through intricate wireframes that served as the blueprint for our digital canvas. With Adobe Fireworks as our trusty companion, we meticulously crafted each pixel, infusing elements of elegance and sophistication inspired by Kelly Hoppen's iconic design ethos.",
                        "As the project unfolded, each line of code became a stroke of artistic expression, weaving together seamlessly to bring our collective vision to life. From the sleek curves of the typography to the mesmerizing color palette that danced across the screen, every detail was meticulously curated to evoke a sense of wonder and intrigue.",
                        "But our journey didn't end with the stroke of a digital pen. With unwavering dedication, we navigated the complexities of development, fine-tuning every element to ensure a flawless user experience. And when the time came to unveil our creation to the world, we stood united, hearts pounding with anticipation as we watched our labor of love take center stage.",
                        "The launch was spectacular, drawing accolades from industry experts and enthusiasts alike. But for us, the true reward lay in the knowledge that we had not only met but exceeded expectations, leaving an indelible mark on the digital landscape."
                    ],
                    "skills": ["UX Design", "Web Development", "SEO"],
                    "tools": [
                        { "name": "Fireworks", "icon": icon_fireworks },
                        { "name": "Sublime Text", "icon": icon_sublime },
                        { "name": "JavaScript", "icon": icon_javascript },
                    ],
                    "screenshots": [{"file": "case_hoppen_full", "caption": "Landing page that was designed and developed for apaiser."}]
                }
            }
        ]
        return (
            <section className="intro col-sm-8 col-sm-push-2" style={{paddingTop: "4rem"}}>
                <div className="portfolioWrap col-sm-12">
                    <div style={{paddingBottom: "1rem"}}>
                        <h2 className="heading-section">Quick Look</h2>
                        <p className="noPad-n">Contact me to see more in-depth case studies.</p>
                    </div>
                    {portfolio.map(( entry ) => (
                        <PortfolioEntry key={entry.key} entry={entry} show={this.state.show} selectedEntry={this.state.entry} showModal={this.showModal} hideModal={this.hideModal} />
                    ))}
                </div>
            </section>
        )
    }
}

export default Portfolio;